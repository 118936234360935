import React from 'react'
import Figure from './Figure'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import PortableText from '@sanity/block-content-to-react'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'
import Vimeo from '@u-wave/react-vimeo';

const serializers = {
  types: {
    mainImage: Figure,
    youtube: ({node}) => {
      const { url } = node
      const id = getYouTubeId(url)
      return (<YouTube videoId={id} />)
    },
    vimeo: ({node}) => {
      const { id } = node
      return (<Vimeo
        video={id}
      />)
    },
  }
}

export default serializers
